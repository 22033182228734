/**
* Generated automatically at built-time (2024-08-30T15:17:11.833Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-stainless-collection-outdoorkueche",templateKey: "sites/102-05bf83e0-35e2-4bed-8f0e-eca472d7fc29"};